@charset "utf-8";

// Our variables
$base-font-family: 'PT Sans', Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.895;
$large-font-size:  $base-font-size * 1.40;

$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #454545;
$background-color: #FFF;
$brand-color:      #00B2EE;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 30%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    700px;
$on-palm:          700px;
$on-laptop:        700px;



// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
        "quirks"
;
