/* Bottom nav elements
 * ============================================== */
    div.bottom-nav {
        align-items: flex-start;
        display: flex;
        justify-content: center;

        > div {
            text-align: center;
            margin: 1.0em;
        }
    }
